<script lang="tsx">
import { defineComponentBaseUiFormHelp } from '@core/app/components/base/ui/form/BaseUiFormHelp.vue'

export default defineComponentBaseUiFormHelp()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormHelp" as *;

@include help {
    @include sim-text-small;
    @include sim-font-medium;

    color: $sim-c-basic-600;
}

</style>
