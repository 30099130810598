<script lang="tsx">
import { defineComponentBaseUiFormDescription } from '@core/app/components/base/ui/form/BaseUiFormDescription.vue'

export default defineComponentBaseUiFormDescription()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormDescription" as *;

@include description {
    @include sim-text-extra-small;
    @include sim-font-regular;

    color: $sim-c-basic-600;
}

</style>
