<script lang="tsx">
import type { SlotsType } from 'vue'

export type BaseUiFormHelpProps<T> = {
    id: string
    message?: string
}

type BaseUiFormHelpSlots<T> = {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormHelp<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormHelpProps<T>, BaseUiFormHelpSlots<T>>) {
    return defineComponent(
        (props: BaseUiFormHelpProps<T>, ctx) => {

            return () => (
                <div
                    id={props.id}
                    class="sim-help"
                >
                    {renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                        <>
                            {props.message}
                        </>
                    ))}
                </div>
            )
        },
        {
            props: {
                id: {
                    type: String as PropType<BaseUiFormHelpProps<T>['id']>,
                    default: options?.props?.id?.default,
                    required: options?.props?.id?.required ?? true,
                },
                message: {
                    type: String as PropType<BaseUiFormHelpProps<T>['message']>,
                    default: options?.props?.message?.default,
                    required: options?.props?.message?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiFormHelpSlots<T>>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiFormHelp()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormHelp.scss" as *;

</style>
