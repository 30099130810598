<script lang="tsx">
import type { SlotsType } from 'vue'

export type BaseUiFormDescriptionProps<T> = {
    id: string
    message?: string
}

type BaseUiFormDescriptionSlots<T> = {
    default: {}
}

type ComponentOptions = {}

export function defineComponentBaseUiFormDescription<T>(options?: ComponentOverrideOptions<ComponentOptions, BaseUiFormDescriptionProps<T>, BaseUiFormDescriptionSlots<T>>) {
    return defineComponent(
        (props: BaseUiFormDescriptionProps<T>, ctx) => {

            return () => (
                <div
                    id={props.id}
                    class="sim-description"
                >
                    {renderSlot(ctx.slots.default, options?.slots?.default, {}, (
                        <>
                            {props.message}
                        </>
                    ))}
                </div>
            )
        },
        {
            props: {
                id: {
                    type: String as PropType<BaseUiFormDescriptionProps<T>['id']>,
                    default: options?.props?.id?.default,
                    required: options?.props?.id?.required ?? true,
                },
                message: {
                    type: String as PropType<BaseUiFormDescriptionProps<T>['message']>,
                    default: options?.props?.message?.default,
                    required: options?.props?.message?.required ?? false,
                },
            },
            slots: Object as SlotsType<BaseUiFormDescriptionSlots<T>>,
            emits: {},
        }
    )
}

export default defineComponentBaseUiFormDescription()

</script>

<style lang="scss" scoped>
@use "@core-scss/components/BaseUiFormDescription.scss" as *;

</style>
